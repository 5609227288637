import {UtilitiesTabsItemInterface} from '../../../utills/interfaces/utilities-tabs.interface';

// SYSTEM ROLES

export const dashboardSystemRolesSearchQuerySelectUrl: string[] = [
  'dashboardSystem',
  'roles',
  'rolesSearchQuery'
];

export const dashboardSystemRolesSelectUrl = [
  'dashboardSystem',
  'roles',
  'roles'
];

export const dashboardSystemRolesTableHeader: TableHeaderInterface[] = [
  {
    title: 'Role ID',
    field: 'id',
    class: 'width-5',
    sort: true
  },
  {
    title: 'Company',
    field: 'companyName',
    class: 'width-30',
    search: true,
    sort: true
  },
  {
    title: 'Title',
    field: 'title',
    class: 'width-30'
  },
  {
    title: 'Description',
    field: 'description',
    class: 'width-30'
  },
  {
    title: '',
    field: 'editLink',
    class: 'width-5'
  }
];

export const dashboardSystemRolesMainTitle: string = 'Roles';

export const dashboardSystemRolesDataName: string = 'roles';

export const dashboardSystemNeighborhoodsDataSelectUrl = [
  'dashboardSystem',
  'roles',
  'neighborhoods'
];

export const dashboardSystemRolesCompanyInfoSelectUrl = [
  'dashboardSystem',
  'roles',
  'company'
];

export const dashboardSystemRolesCompanyInfoLoadingSelectUrl = [
  'dashboardSystem',
  'roles',
  'companyLoading'
];

// SYSTEM USERS

export const dashboardSystemUsersTableHeaders: TableHeaderInterface[] = [
  {
    title: 'User ID',
    field: 'id',
    class: 'width-5',
    sort: true
  },
  {
    title: 'Company',
    field: 'companyName',
    class: 'width-15',
    search: true,
    sort: true
  },
  {
    title: 'First name',
    field: 'first',
    class: 'width-15',
    search: true
  },
  {
    title: 'Last name',
    field: 'last',
    class: 'width-15',
    search: true
  },
  {
    title: 'Email',
    field: 'email',
    class: 'width-15',
    search: true
  },
  {
    title: 'Role',
    field: 'role',
    class: 'width-10',
    search: true
  },
  {
    title: 'Status',
    field: 'active',
    class: 'width-5'
  },
  {
    title: '',
    field: 'editLink',
    class: 'width-5'
  }
];

export const dashboardSystemUsersQuerySelectUrl: string[] = [
  'dashboardSystem',
  'users',
  'usersSearchQuery'
];

export const dashboardSystemUsersDataSelectUrl: string[] = [
  'dashboardSystem',
  'users',
  'users'
];

export const dashboardSystemUserDataSelectUrl: string[] = [
  'dashboardSystem',
  'users',
  'user'
];

export const dashboardSystemUsersMainTitle: string = 'USERS';

export const dashboardSystemUsersExportCSV: string = `users/csv`;

export const dashboardSystemUsersDataName: string = 'users';

// SYSTEM BUILDING

export const dashboardSystemBuildingsTableHeaders: TableHeaderInterface[] = [
  {
    title: 'Name',
    field: 'name',
    class: 'width-19',
    search: true,
    sort: true
  },
  {
    title: 'Company',
    field: 'companyName',
    class: 'width-14',
    search: true,
    sort: true
  },
  {
    title: 'Community',
    field: 'buildingGroupName',
    class: 'width-19',
    search: true
  },
  {
    title: 'Code',
    field: 'propCode',
    class: 'width-7',
    sort: true
  },
  {
    title: 'Building #',
    field: 'partition',
    class: 'width-9'
    // sort: true
  },
  {
    title: 'Region',
    field: 'regionName',
    class: 'width-14',
    search: true,
    sort: true
  },
  {
    title: 'Address',
    field: 'address',
    class: 'width-25',
    search: true,
    sort: true
  },
  {
    title: 'Zip',
    field: 'zip',
    class: 'width-9',
    sort: true
  },
  {
    title: 'Status',
    field: 'buildingStatus',
    class: 'width-7'
  },
  {
    title: '',
    field: 'totallyDelete',
    class: 'width-5'
  },
  {
    title: '',
    field: 'editLink',
    class: 'width-5'
  }
];

export const dashboardSystemBuildingTabs: UtilitiesTabsItemInterface[] = [
  {
    title: 'General',
    route: 'general',
    iconClass: ''
  },
  {
    title: 'Access',
    route: 'access',
    iconClass: ''
  },
  {
    title: 'SelfTour',
    route: 'self-tour',
    iconClass: ''
  }
];

export const dashboardSystemBuildingAmenitiesArr = [
  {value: 'gym', title: 'Gym'},
  {value: 'pool', title: 'Pool'},
  {value: 'garden', title: 'Garden'},
  {value: 'game_room', title: 'Game room'},
  {value: 'laundry', title: 'Laundry'},
  {value: 'leasing_office', title: 'Leasing office'}
  // {value: 'fitness_center', title: 'Fitness Center'},
  // {value: 'coworking_offices', title: 'Coworking Offices'},
  // {value: 'screening_room', title: 'Screening Room'},
  // {value: 'childrens_playroom', title: `Children's Playroom`},
  // {value: 'library_lounge', title: 'Library Lounge'},
  // {value: 'sky_lounge', title: 'Sky Lounge'}
];

export const buildingsStatusList = [
  {value: '', title: 'All'},
  {value: 'enabled', title: 'Enabled'},
  {value: 'disabled', title: 'Disabled'}
];

export const buildingsStatus: any = {
  enabled: 'Enabled',
  disabled: 'Disabled'
};

export const dashboardSystemBuildingsMainTitle: string = 'BUILDINGS';

export const dashboardSystemBuildingsQuerySelectUrl: string[] = [
  'dashboardSystem',
  'buildings',
  'buildingsSearchQuery'
];

export const dashboardSystemBuildingsDataSelectUrl: string[] = [
  'dashboardSystem',
  'buildings',
  'buildings'
];

export const dashboardSystemBuildingDataSelectUrl: string[] = [
  'dashboardSystem',
  'buildings',
  'building'
];
export const dashboardSystemBuildingIdSelectUrl: string[] = [
  'dashboardSystem',
  'buildings',
  'buildingId'
];
export const dashboardSystemBuildingCommonAreaAccessDoorsSelectUrl: string[] = [
  'dashboardSystem',
  'buildings',
  'buildingCommonAreaAccessDoors'
];
export const dashboardSystemBuildingCommonAreaAccessUalsSelectUrl: string[] = [
  'dashboardSystem',
  'buildings',
  'buildingCommonAreaAccessUals'
];
export const dashboardSystemBuildingVacantApartmentAutomationLeasingUalSelectUrl: string[] = [
  'dashboardSystem',
  'buildings',
  'vacantApartmentAutomationLeasingUal'
];
export const dashboardSystemBuildingVacantApartmentAutomationOperationsUalSelectUrl: string[] = [
  'dashboardSystem',
  'buildings',
  'vacantApartmentAutomationOperationsUal'
];

export const dashboardSystemBuildingsDataName: string = 'buildings';

// SYSTEM COMMUNITY

export const dashboardSystemBuildingsGroupTableHeaders: TableHeaderInterface[] = [
  {
    title: 'Id',
    field: 'buildingGroupId',
    class: 'width-5',
    sort: true
  },
  {
    title: 'Name',
    field: 'name',
    class: 'width-25',
    search: true,
    sort: true
  },
  {
    title: 'Company',
    field: 'companyName',
    class: 'width-15',
    search: true,
    sort: true
  },
  {
    title: 'Phone',
    field: 'phone',
    class: 'width-20',
    search: true
  },
  {
    title: 'Status',
    field: 'buildingsGroupStatus',
    class: 'width-10'
  },
  {
    title: '',
    field: 'totallyDelete',
    class: 'width-5'
  },
  {
    title: '',
    field: 'editLink',
    class: 'width-5'
  }
];

export const dashboardSystemBuildingsGroupMainTitle: string = 'Community';

export const dashboardSystemBuildingsGroupQuerySelectUrl: string[] = [
  'dashboardSystem',
  'buildingsGroup',
  'buildingsGroupSearchQuery'
];

export const dashboardSystemBuildingsGroupDataSelectUrl: string[] = [
  'dashboardSystem',
  'buildingsGroup',
  'buildingsGroup'
];

export const dashboardSystemBuildingGroupDataSelectUrl: string[] = [
  'dashboardSystem',
  'buildingsGroup',
  'buildingGroup'
];

export const dashboardSystemBuildingsGroupDataName: string = 'buildingsGroup';

// SETTINGS AUTOMATIONS LOGS

export const dashboardSystemAutomationsLogsTableHeaders: TableHeaderInterface[] = [
  {
    title: 'Correlation ID',
    field: 'corellationId',
    class: 'width-10',
    search: true
  },
  {
    title: 'Started At',
    field: 'startedAt',
    class: 'width-8',
    search: true,
    sort: true
  },
  {
    title: 'Ended At',
    field: 'endedAt',
    class: 'width-8',
    search: true,
    sort: true
  },
  {
    title: 'Status',
    field: 'automationLogStatus',
    class: 'width-7',
    search: true
  },
  {
    title: 'Automation Command',
    field: 'automationCommand',
    class: 'width-16',
    search: true
  },
  {
    title: 'Company Name',
    field: 'companyName',
    class: 'width-10',
    search: true
  },
  {
    title: 'Resolved',
    field: 'resolved',
    class: 'width-8',
    search: true
  },
  {
    title: 'Tenant ID',
    field: 'yardiTenantId',
    class: 'width-8',
    search: true
  },
  {
    title: 'Building ID',
    field: 'buildingId',
    class: 'width-8',
    search: true
  },
  {
    title: 'Actions',
    field: 'actionsColumn',
    class: 'width-8',
    search: true
  }
];

export const dashboardSystemAutomationsLogsMainTitle: string =
  'AUTOMATIONS LOGS';

export const dashboardSystemAutomationsLogsQuerySelectUrl: string[] = [
  'dashboardSystem',
  'automationsLogs',
  'automationsLogsSearchQuery'
];

export const dashboardSystemAutomationsLogsDataSelectUrl: string[] = [
  'dashboardSystem',
  'automationsLogs',
  'automationsLogs'
];
export const dashboardSystemAutomationLogsStepsDataSelectUrl: string[] = [
  'dashboardSystem',
  'automationsLogs',
  'automationSteps'
];

export const dashboardSystemAutomationsLogsDataName: string =
  'automationReportData';

export const dashboardSystemAutomationsLogsExportCSV: string = `automation-report/csv`;

export const dashboardSystemAutomationsLogsExportPDF: string = `automation-report/pdf`;

// SYSTEM COMPANY

export const dashboardSystemCompaniesTableHeaders: TableHeaderInterface[] = [
  {
    title: 'ID',
    field: 'companyId',
    class: 'width-5'
  },
  {
    title: 'Name',
    field: 'name',
    class: 'width-25',
    search: true,
    sort: true
  },
  {
    title: 'Owner Name',
    field: 'ownerName',
    class: 'width-25'
  },
  {
    title: 'Created',
    field: 'created',
    class: 'width-15',
    sort: true
  },
  {
    title: 'Modified',
    field: 'modified',
    class: 'width-15',
    sort: true
  },
  {
    title: 'Status',
    field: 'isActive',
    class: 'width-10',
    sort: true
  },
  {
    title: '',
    field: 'editLink',
    class: 'width-5'
  }
];

export const dashboardSystemCompaniesMainTitle: string = 'COMPANIES';

export const dashboardSystemCompaniesQuerySelectUrl: string[] = [
  'dashboardSystem',
  'companies',
  'companiesSearchQuery'
];

export const dashboardSystemCompaniesDataSelectUrl: string[] = [
  'dashboardSystem',
  'companies',
  'companies'
];

export const dashboardSystemCompanyDataSelectUrl: string[] = [
  'dashboardSystem',
  'companies',
  'company'
];

export const dashboardSystemTimezonesDataSelectUrl: string[] = [
  'dashboardSystem',
  'companies',
  'timezones'
];
export const dashboardSystemCompanyStatusesDataSelectUrl: string[] = [
  'dashboardSystem',
  'companies',
  'companyStatuses'
];

export const dashboardSystemCompaniesDataName: string = 'companies';

// SYSTEM ACCESS AUTOMATIONS

export const dashboardSystemAccessAutomationsMainTitle: string =
  'LIST OF AUTOMATIONS';

export const dashboardSystemAccessAutomationsQuerySelectUrl: string[] = [
  'dashboardSystem',
  'accessAutomations',
  'accessAutomationsSearchQuery'
];

export const dashboardSystemAccessAutomationsDataSelectUrl: string[] = [
  'dashboardSystem',
  'accessAutomations',
  'accessAutomations'
];

export const dashboardSystemAccessAutomationDataSelectUrl: string[] = [
  'dashboardSystem',
  'accessAutomations',
  'accessAutomation'
];

export const dashboardSystemAccessAutomationResidentsOperandValuesDataSelectUrl: string[] = [
  'dashboardSystem',
  'accessAutomations',
  'accessAutomationResidentsOperandValues'
];
export const dashboardSystemAccessAutomationApartmentsOperandValuesDataSelectUrl: string[] = [
  'dashboardSystem',
  'accessAutomations',
  'accessAutomationApartmentsOperandValues'
];
export const dashboardSystemAccessAutomationMoveInOperandValuesDataSelectUrl: string[] = [
  'dashboardSystem',
  'accessAutomations',
  'accessAutomationMoveInOperandValues'
];
export const dashboardSystemAccessAutomationMoveOutOperandValuesDataSelectUrl: string[] = [
  'dashboardSystem',
  'accessAutomations',
  'accessAutomationMoveOutOperandValues'
];

export const dashboardSystemAccessAutomationAllActionsDataSelectUrl: string[] = [
  'dashboardSystem',
  'accessAutomations',
  'allActions'
];
export const dashboardSystemAccessAutomationCurrentActionDataSelectUrl: string[] = [
  'dashboardSystem',
  'accessAutomations',
  'currentAction'
];
export const dashboardSystemAccessAutomationAvailableCommandsListDataSelectUrl: string[] = [
  'dashboardSystem',
  'accessAutomations',
  'availableCommandsList'
];
export const dashboardSystemAccessAutomationAllOperandsListDataSelectUrl: string[] = [
  'dashboardSystem',
  'accessAutomations',
  'allOperands'
];
export const dashboardSystemAccessAutomationLoadingDataSelectUrl: string[] = [
  'dashboardSystem',
  'accessAutomations',
  'accessAutomationLoading'
];
export const dashboardSystemAccessAutomationBuildingCommonAreaDataSelectUrl: string[] = [
  'dashboardSystem',
  'accessAutomations',
  'buildingCommonArea'
];
export const dashboardSystemAccessAutomationLeasingUalsDataSelectUrl: string[] = [
  'dashboardSystem',
  'accessAutomations',
  'leasingUals'
];
export const dashboardSystemAccessAutomationVacancyUalsDataSelectUrl: string[] = [
  'dashboardSystem',
  'accessAutomations',
  'vacancyUals'
];
export const dashboardSystemAccessAutomationConnectOperandValueLoadingDataSelectUrl: string[] = [
  'dashboardSystem',
  'accessAutomations',
  'connectOperandValueLoading'
];
export const dashboardSystemAccessAutomationDisconnectOperandValueLoadingDataSelectUrl: string[] = [
  'dashboardSystem',
  'accessAutomations',
  'disconnectOperandValueLoading'
];

export const dashboardSystemAccessAutomationsDataName: string = 'data';

export const accessAutomationStatusList = [
  {value: '', title: 'All'},
  {value: 'true', title: 'Active'},
  {value: 'false', title: 'Not active'}
];

export const accessAutomationOperand = {
  APARTMENTS_STATUS: 1,
  RESIDENTS_STATUS: 2,
  MOVE_IN: 3,
  MOVE_OUT: 4
};

// SYSTEM DOORS

export const dashboardSystemDoorsTabs: UtilitiesTabsItemInterface[] = [
  {
    title: 'Salto Locks',
    route: 'salto',
    iconClass: ''
  },
  {
    title: 'Igloo Locks',
    route: 'igloo',
    iconClass: ''
  }
];

export const openMode = [
  {
    id: 0,
    name: 'STANDARD'
  },
  {
    id: 1,
    name: 'OFFICE.'
  },
  {
    id: 2,
    name: 'TIMED OFFICE'
  },
  {
    id: 3,
    name: 'AUTOMATIC OPENING.'
  },
  {
    id: 4,
    name: 'TOGGLE'
  },
  {
    id: 5,
    name: 'TIMED TOGGLE.'
  },
  {
    id: 6,
    name: 'KEYPAD ONLY.'
  },
  {
    id: 7,
    name: 'TIMED KEYPAD.'
  },
  {
    id: 8,
    name: 'KEY + PIN.'
  },
  {
    id: 9,
    name: 'TIMED KEY + PIN.'
  },
  {
    id: 10,
    name: 'AUTOMATIC OPENING + OFFICE.'
  },
  {
    id: 11,
    name: 'AUTOMATIC OPENING + TOGGLE'
  },
  {
    id: 12,
    name: 'AUTOMATIC CHANGES'
  },
  {
    id: 13,
    name: 'EXIT LEAVES OPEN'
  },
  {
    id: 14,
    name: 'TOGGLE + EXIT LEAVES OPEN'
  },
  {
    id: 15,
    name: 'KEYPAD + EXIT LEAVES OPEN'
  }
];

export const dashboardSystemDoorsTableHeaders: TableHeaderInterface[] = [
  {
    title: '',
    field: 'refresh',
    class: 'width-5'
  },
  {
    title: 'Company',
    field: 'companyName',
    class: 'width-15',
    search: true
    // sort: true
  },
  {
    title: 'Name',
    field: 'name',
    class: 'width-25',
    search: true,
    sort: true
  },
  {
    title: 'Description',
    field: 'description',
    class: 'width-25',
    search: true,
    sort: true
  },
  {
    title: 'Battery',
    field: 'batteryStatus',
    class: 'width-8'
  },
  {
    title: 'Ext id',
    field: 'saltoId',
    class: 'width-35',
    search: true,
    sort: true
  },
  {
    title: '',
    field: 'editLink',
    class: 'width-5'
  }
];

export const dashboardSystemDoorsMainTitle: string = 'DOORS';

export const dashboardSystemDoorsQuerySelectUrl: string[] = [
  'dashboardSystem',
  'doors',
  'doorsSearchQuery'
];

export const dashboardSystemDoorsDataSelectUrl: string[] = [
  'dashboardSystem',
  'doors',
  'doors'
];

export const dashboardSystemDoorDataSelectUrl: string[] = [
  'dashboardSystem',
  'doors',
  'door'
];

export const dashboardSystemDoorsDataName: string = 'doors';

// IGLOO DOORS

export const dashboardSystemDoorsIglooTableHeaders: TableHeaderInterface[] = [
  {
    title: '',
    field: 'refresh',
    class: 'width-5'
  },
  {
    title: 'Company',
    field: 'companyNameIgloo',
    class: 'width-20',
    search: true
    // sort: true
  },
  {
    title: 'Lock ID',
    field: 'lockId',
    class: 'width-15',
    search: true
    // sort: true
  },
  {
    title: 'Name',
    field: 'name',
    class: 'width-15',
    search: true
    // sort: true
  },
  {
    title: 'Battery',
    field: 'batteryLevel',
    class: 'width-7'
  },
  {
    title: 'Masterpin',
    field: 'masterpin',
    class: 'width-15'
  },
  {
    title: 'Paired at',
    field: 'pairedAt',
    class: 'width-15',
    search: true
    // sort: true
  } /*
  {
    title: 'Description',
    field: 'description',
    class: 'width-25',
    search: true,
    sort: true
  },
  {
    title: 'Battery',
    field: 'batteryStatus',
    class: 'width-8'
  },
  {
    title: 'Ext id',
    field: 'saltoId',
    class: 'width-35',
    search: true,
    sort: true
  },*/,
  {
    title: '',
    field: 'editLink',
    class: 'width-5'
  }
];

export const dashboardSystemDoorsIglooMainTitle: string = 'IGLOO LOCKS';

export const dashboardSystemDoorsIglooQuerySelectUrl: string[] = [
  'dashboardSystem',
  'doors',
  'doorsIglooSearchQuery'
];

export const dashboardSystemDoorsIglooDataSelectUrl: string[] = [
  'dashboardSystem',
  'doors',
  'doorsIgloo'
];

export const dashboardSystemDoorIglooDataSelectUrl: string[] = [
  'dashboardSystem',
  'doors',
  'doorIgloo'
];

export const dashboardSystemDoorsIglooDataName: string = 'iglooLocks';

// SYSTEM ACCESS LEVELS

export const dashboardSystemAccessLevelsTableHeaders: TableHeaderInterface[] = [
  {
    title: 'Name',
    field: 'name',
    class: 'width-20',
    sort: true
  },
  {
    title: 'Company',
    field: 'companyName',
    class: 'width-15',
    search: true
    // sort: true
  },
  {
    title: 'Description',
    field: 'description',
    class: 'width-65'
  },
  {
    title: '',
    field: 'editLink',
    class: 'width-5'
  }
];

export const dashboardSystemAccessLevelsMainTitle: string = 'Door groups';

export const dashboardSystemAccessLevelsQuerySelectUrl: string[] = [
  'dashboardSystem',
  'accessLevels',
  'accessLevelsSearchQuery'
];

export const dashboardSystemAccessLevelsDataSelectUrl: string[] = [
  'dashboardSystem',
  'accessLevels',
  'accessLevels'
];

export const dashboardSystemAccessLevelDataSelectUrl: string[] = [
  'dashboardSystem',
  'accessLevels',
  'accessLevel'
];

export const dashboardSystemAccessLevelDoorsDataSelectUrl: string[] = [
  'dashboardSystem',
  'accessLevels',
  'accessLevelAvailableDoors'
];

export const dashboardSystemAccessLevelDoorsAllDataSelectUrl: string[] = [
  'dashboardSystem',
  'accessLevels',
  'accessLevelAvailableDoorsAll'
];

export const dashboardSystemAccessLevelZonesDataSelectUrl: string[] = [
  'dashboardSystem',
  'accessLevels',
  'accessLevelAvailableZones'
];

export const dashboardSystemAccessLevelZonesAllDataSelectUrl: string[] = [
  'dashboardSystem',
  'accessLevels',
  'accessLevelAvailableZonesAll'
];

export const dashboardSystemAccessLevelsDataName: string = 'accessLevels';

// SYSTEM ZONES

export const dashboardSystemZonesTableHeaders: TableHeaderInterface[] = [
  {
    title: 'ID',
    field: 'id',
    class: 'width-5'
  },
  {
    title: 'Company',
    field: 'companyName',
    class: 'width-15',
    search: true
    // sort: true
  },
  {
    title: 'Name',
    field: 'name',
    class: 'width-45',
    search: true
  },
  {
    title: 'Description',
    field: 'description',
    class: 'width-45',
    search: true
  },
  {
    title: '',
    field: 'editLink',
    class: 'width-5'
  }
];

export const dashboardSystemZonesMainTitle: string = 'ZONES';

export const dashboardSystemZonesQuerySelectUrl: string[] = [
  'dashboardSystem',
  'zones',
  'zonesSearchQuery'
];

export const dashboardSystemZonesDataSelectUrl: string[] = [
  'dashboardSystem',
  'zones',
  'zones'
];

export const dashboardSystemZoneDataSelectUrl: string[] = [
  'dashboardSystem',
  'zones',
  'zone'
];

export const dashboardSystemZoneDoorsDataSelectUrl: string[] = [
  'dashboardSystem',
  'zones',
  'zoneAvailableDoors'
];

export const dashboardSystemZoneDoorsAllDataSelectUrl: string[] = [
  'dashboardSystem',
  'zones',
  'zoneAvailableDoorsAll'
];

export const dashboardSystemZoneUalsDataSelectUrl: string[] = [
  'dashboardSystem',
  'zones',
  'zoneAvailableUals'
];

export const dashboardSystemZoneUalsAllDataSelectUrl: string[] = [
  'dashboardSystem',
  'zones',
  'zoneAvailableUalsAll'
];

export const dashboardSystemZonesDataName: string = 'zones';

// SYSTEM TIMETABLES

export const dashboardSystemTimetablesDataSelectUrl: string[] = [
  'dashboardSystem',
  'timetables',
  'timetables'
];

export const dashboardSystemTimetablesSearchQuerySelectUrl: string[] = [
  'dashboardSystem',
  'timetables',
  'timetablesSearchQuery'
];

export const dashboardSystemTimetableDataSelectUrl: string[] = [
  'dashboardSystem',
  'timetables',
  'timetable'
];

// SYSTEM KEYS

export const dashboardSystemKeysTabsTabs: UtilitiesTabsItemInterface[] = [
  {
    title: 'Read key',
    route: 'read',
    iconClass: ''
  },
  {
    title: 'Delete key',
    route: 'delete',
    iconClass: ''
  }
  // {
  //   title: 'Automatic key update',
  //   route: 'automatic-update',
  //   iconClass: ''
  // }
];

export const dashboardSystemKeysEncodersDataSelectUrl: string[] = [
  'dashboardSystem',
  'systemKeys',
  'encoders'
];

export const dashboardSystemKeysKeyDataSelectUrl: string[] = [
  'dashboardSystem',
  'systemKeys',
  'key'
];

// SYSTEM AUDIT LOGS

export const dashboardSystemAuditLogsTableHeaders: TableHeaderInterface[] = [
  {
    title: 'Timestamp',
    field: 'date',
    class: 'width-12',
    sort: true
  },
  {
    title: 'Company',
    field: 'domainCompany.name',
    class: 'width-10',
    sort: true
  },
  {
    title: 'User ID',
    field: 'userId',
    class: 'width-7',
    sort: true
  },
  {
    title: 'User Type',
    field: 'domainUser.roleTitle',
    class: 'width-10',
    sort: true
  },
  {
    title: 'User Name',
    field: 'domainUser.first',
    class: 'width-15'
    // sort: true
  },
  {
    title: 'Action Type',
    field: 'action',
    class: 'width-10',
    sort: true
  },
  {
    title: 'Entity ID',
    field: 'entityId',
    class: 'width-5',
    // sort: true
  },
  {
    title: 'Entity Affected',
    field: 'entity',
    class: 'width-10',
    // sort: true
  },
  {
    title: 'Entity Name',
    field: 'entityName',
    class: 'width-10',
    // sort: true
  },
  {
    title: 'Details',
    field: 'message',
    class: 'width-16'
  },
  {
    title: '',
    field: 'delta',
    class: 'width-5'
  }
];

export const dashboardSystemAuditLogsMainTitle: string = 'AUDIT LOG';

export const dashboardSystemAuditLogsQuerySelectUrl: string[] = [
  'dashboardSystem',
  'auditLogs',
  'auditLogsSearchQuery'
];

export const dashboardSystemAuditLogsDataSelectUrl: string[] = [
  'dashboardSystem',
  'auditLogs',
  'auditLogs'
];

export const dashboardSystemAuditLogsActionsTypesDataSelectUrl: string[] = [
  'dashboardSystem',
  'auditLogs',
  'actionTypes'
];

export const dashboardSystemAuditLogsEntitiesDataSelectUrl: string[] = [
  'dashboardSystem',
  'auditLogs',
  'entities'
];

export const dashboardSystemAuditLogsUserTypesDataSelectUrl: string[] = [
  'dashboardSystem',
  'auditLogs',
  'userTypes'
];
export const dashboardSystemAuditLogsDeltaDataSelectUrl: string[] = [
  'dashboardSystem',
  'auditLogs',
  'delta'
];

export const dashboardSystemAuditLogsDataName: string = 'data';

export const dashboardSystemAuditLogsExportCSV: string =
  'acl/logs/events/export/csv';
export const dashboardSystemAuditLogsExportPDF: string =
  'acl/logs/events/export/pdf';
