import {Action} from '@ngrx/store';
import {QueryParamsInterface} from '../../../../interfaces/query-params.interface';
import {
  CompanyStatusesForSubmitInterface,
  StatusesRequestResponseInterface
} from '../../interfaces/company.interface';

export enum CompanyActions {
  GetCompanies = '[Companies] Get Companies',
  GetCompaniesSuccess = '[Companies] Get Companies Success',
  UpdateCompaniesSearchQueryRaw = '[Companies] Update Search Query Raw',
  GetCompany = '[Company Item] Get Company',
  GetCompanySuccess = '[Company Item] Get Company Success',
  SaveCompany = '[Company Item] Save Company',
  DeleteCompanyFromStore = '[Company Item] Delete Company From Store',
  CreateCompany = '[Company Item] Create Company',
  DeleteCompany = '[Company Item] Delete Company',
  GetTimezones = '[Company Item] Get Timezones',
  GetTimezonesSuccess = '[Company Item] Get Timezones Success',

  GetCompanyStatuses = '[Company Item] Get Company Statuses',
  GetCompanyStatusesSuccess = '[Company Item] Get Company Statuses Success',

  UpdateCompanyStatuses = '[Company Item] Update Company Statuses',
  CreateCompanyStatuses = '[Company Item] Create Company Statuses',
  CreateCompanyStatusesSuccess = '[Company Item] Create Company Statuses Success',
  DeleteFromStoreCompanyStatuses = '[Company Item] Delete From Store Company Statuses',
}

export class GetCompaniesAction implements Action {
  type = CompanyActions.GetCompanies;

  constructor(public payload: QueryParamsInterface) {
  }
}

export class GetCompaniesSuccessAction implements Action {
  type = CompanyActions.GetCompaniesSuccess;

  constructor(public payload: any) {
  }
}

export class UpdateCompaniesSearchQueryRawAction implements Action {
  type = CompanyActions.UpdateCompaniesSearchQueryRaw;

  constructor(public payload: QueryParamsInterface) {
  }
}

export class GetCompanyAction implements Action {
  type = CompanyActions.GetCompany;

  constructor(public payload: number) {
  }
}

export class SaveCompanyAction implements Action {
  type = CompanyActions.SaveCompany;

  constructor(public payload) {
  }
}

export class GetCompanySuccessAction implements Action {
  type = CompanyActions.GetCompanySuccess;

  constructor(public payload) {
  }
}

export class DeleteCompanyFromStoreAction implements Action {
  type = CompanyActions.DeleteCompanyFromStore;

  constructor(public payload?) {
  }
}

export class CreateCompanyAction implements Action {
  type = CompanyActions.CreateCompany;

  constructor(public payload: {data: any, statuses: CompanyStatusesForSubmitInterface}) {
  }
}

export class DeleteCompanyAction implements Action {
  type = CompanyActions.DeleteCompany;

  constructor(public payload: number) {
  }
}

export class GetTimezonesAction implements Action {
  type = CompanyActions.GetTimezones;

  constructor(public payload?: any) {
  }
}

export class GetTimezonesSuccessAction implements Action {
  type = CompanyActions.GetTimezonesSuccess;

  constructor(public payload?: any) {
  }
}

export class GetCompanyStatusesAction implements Action {
  type = CompanyActions.GetCompanyStatuses;

  constructor(public payload?: number) {
  }
}

export class GetCompanyStatusesSuccessAction implements Action {
  type = CompanyActions.GetCompanyStatusesSuccess;

  constructor(public payload: StatusesRequestResponseInterface) {}
}

export class UpdateCompanyStatusesAction implements  Action {
  type = CompanyActions.UpdateCompanyStatuses;

  constructor(public payload: {companyId: number, body: CompanyStatusesForSubmitInterface}) {
  }
}

export class CreateCompanyStatusesAction implements  Action {
  type = CompanyActions.CreateCompanyStatuses;

  constructor(public payload: {companyId: number, body: CompanyStatusesForSubmitInterface}) {
  }
}
export class CreateCompanyStatusesSuccessAction implements  Action {
  type = CompanyActions.CreateCompanyStatusesSuccess;

  constructor(public payload: boolean) {
  }
}
export class DeleteFromStoreCompanyStatusesAction implements  Action {
  type = CompanyActions.DeleteFromStoreCompanyStatuses;

  constructor(public payload?) {
  }
}

export type CompanyActionsUnion =
  GetCompaniesAction
  | GetCompaniesSuccessAction
  | UpdateCompaniesSearchQueryRawAction
  | GetCompanyAction
  | GetCompanySuccessAction
  | SaveCompanyAction
  | DeleteCompanyFromStoreAction
  | CreateCompanyAction
  | DeleteCompanyAction
  | GetTimezonesAction
  | GetTimezonesSuccessAction
  | GetCompanyStatusesAction
  | GetCompanyStatusesSuccessAction
  | UpdateCompanyStatusesAction
  | CreateCompanyStatusesAction
  | CreateCompanyStatusesSuccessAction
  | DeleteFromStoreCompanyStatusesAction;
