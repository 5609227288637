import { AuditLogActions } from '../actions/audit-log.actions';
import * as moment from 'moment';
const ɵ0 = [];
const initialState = {
    auditLogs: { data: ɵ0, total: 0 },
    auditLogsSearchQuery: null,
    actionTypes: null,
    entities: null,
    userTypes: null,
    delta: null
};
export function auditLogsReducer(state = initialState, action) {
    switch (action.type) {
        case AuditLogActions.GetAuditLogsSuccess: {
            return Object.assign({}, state, { auditLogs: action.payload });
        }
        case AuditLogActions.UpdateAuditLogsSearchQuery: {
            const a = action.payload;
            if (a.dateFrom) {
                a.dateFrom = moment(a.dateFrom).format('YYYY-MM-DD');
            }
            if (a.dateTo) {
                a.dateTo = moment(a.dateTo).format('YYYY-MM-DD');
            }
            return Object.assign({}, state, { auditLogsSearchQuery: Object.assign({}, state.auditLogsSearchQuery, a) });
        }
        case AuditLogActions.GetActionTypesSuccess: {
            return Object.assign({}, state, { actionTypes: action.payload });
        }
        case AuditLogActions.GetEntitySuccess: {
            return Object.assign({}, state, { entities: action.payload });
        }
        case AuditLogActions.GetUserTypesSuccess: {
            return Object.assign({}, state, { userTypes: action.payload });
        }
        case AuditLogActions.DeleteDataFromStore: {
            return Object.assign({}, state, { auditLogs: { data: [], total: 0 }, actionTypes: null, entities: null, userTypes: null, delta: null });
        }
        case AuditLogActions.GetLogDelta: {
            return Object.assign({}, state, { delta: action.payload });
        }
        case AuditLogActions.DeleteLogDeltaFromStore: {
            return Object.assign({}, state, { delta: null });
        }
        default: {
            return state;
        }
    }
}
export { ɵ0 };
